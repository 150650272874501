/**
 * Mixin for NwForm[TaskType].vue
 */

import {BFormGroup} from "bootstrap-vue";
import NwForm from "@/views/kiosk/components/kskNursingWork/NwForm.vue";
import {ValidationProvider} from "vee-validate";

export default {
  model: {
    prop: 'nw-form-task-type-value',
    event: 'nw-form-task-type-input',
  },
  components: {BFormGroup, NwForm, ValidationProvider},
  props: {
    nwFormTaskTypeValue: {
      type: Object,
      require: true
    },
    selectOptions: Object,
  },
  data() {
    return {
      form: JSON.parse(JSON.stringify(this.nwFormTaskTypeValue)),
    }
  },
  watch: {
    nwFormTaskTypeValue: {
      handler: function (newValue) {
        this.form = JSON.parse(JSON.stringify(newValue))
      },
      deep: true,
    }
  },
  methods: {
    // Handle new form data from NwForm.vue
    handleNwFormInput(newValue) {
      this.form = newValue
      this.emitFormData()
    },
    emitFormData() {
      this.$emit('nw-form-task-type-input', JSON.parse(JSON.stringify(this.form)))
    }
  }
}