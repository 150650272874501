import common from "@/common";
import NwFormBt from "@/views/kiosk/components/kskNursingWork/NwFormBt.vue";
import NwFormBp from "@/views/kiosk/components/kskNursingWork/NwFormBp.vue";
import NwFormBo from "@/views/kiosk/components/kskNursingWork/NwFormBo.vue";
import NwFormGlucose from "@/views/kiosk/components/kskNursingWork/NwFormGlucose.vue";
import NwFormBreath from "@/views/kiosk/components/kskNursingWork/NwFormBreath.vue";
import NwFormConscious from "@/views/kiosk/components/kskNursingWork/NwFormConscious.vue";
import NwFormDietary from "@/views/kiosk/components/kskNursingWork/NwFormDietary.vue";
import NwFormEmotional from "@/views/kiosk/components/kskNursingWork/NwFormEmotional.vue";
import NwFormHeight from "@/views/kiosk/components/kskNursingWork/NwFormHeight.vue";
import NwFormWeight from "@/views/kiosk/components/kskNursingWork/NwFormWeight.vue";
import {mapState} from "vuex";

export default {
  components: {
    NwFormBt,
    NwFormBp,
    NwFormBo,
    NwFormGlucose,
    NwFormBreath,
    NwFormConscious,
    NwFormDietary,
    NwFormEmotional,
    NwFormHeight,
    NwFormWeight,
  },
  data() {
    const tasks = [
      {
        taskType: "bt",
        requestTaskType: "BodyTemperature",
        title: this.$t(`kiosk.temperature`),
        formData: {
          Temperature: "",
        },
      },
      {
        taskType: 'bp',
        requestTaskType: "BloodPressure",
        title: this.$t(`kiosk.blood_pressure`),
        formData: {
          Systolic: "",
          Diastolic: "",
          Pulse: "",
        },
      },
      {
        taskType: 'bo',
        requestTaskType: "SpO2",
        title: this.$t(`kiosk.oximetry`),
        formData: {
          SpO2: "",
        },
      },
      {
        taskType: 'glucose',
        requestTaskType: "Glucose",
        title: this.$t(`kiosk.blood_glucose`),
        formData: {
          Glucose: "",
          MeasuredPeriod: null,
        }
      },
      {
        taskType: 'breath',
        requestTaskType: "Breath",
        title: this.$t(`kiosk.breath`),
        formData: {
          Breaths: "",
        },
      },
      {
        taskType: 'conscious',
        requestTaskType: "Conscious",
        title: this.$t(`kiosk.conscious`),
        formData: {
          Conscious: null,
        },
      },
      {
        taskType: 'dietary',
        requestTaskType: "Dietary",
        title: this.$t(`kiosk.dietary`),
        formData: {
          Dietary: null,
        },
      },
      {
        taskType: 'emotional',
        requestTaskType: "Emotional",
        title: this.$t(`kiosk.emotional`),
        formData: {
          Emotional: null,
        },
      },
      {
        taskType: 'weight',
        requestTaskType: "Weight",
        title: this.$t(`kiosk.weight`),
        formData: {
          Weight: "",
        },
      },
      {
        taskType: 'height',
        requestTaskType: "Height",
        title: this.$t(`kiosk.height`),
        formData: {
          Height: "",
        },
      },
    ]

    tasks.forEach(task => {
      if (!task.taskType) return
      task.icon = task.icon ?? require(`@/assets/images/kiosk/kskNursingWork/${task.taskType}.svg`)
    })

    return {
      tasks,
      tasksSelectOptions: {},

      // Modal
      currentTaskType: "",
    }
  },
  computed: {
    ...mapState('app', ['userProfile']),
    currentTask() {
      return this.tasks.find(w => w.taskType === this.currentTaskType)
    },
  },
  created() {
    common.apiGetData("/kiosk/GetMeasureDropDownList")
      .then(res => {
        this.tasksSelectOptions = res.data
      })
  },
  methods: {
    // Modal
    handleModalShow() {
      this.currentTask.defaultFormData = JSON.parse(JSON.stringify(this.currentTask.formData))

      if (!this.editingLog) {
        this.$set(this.currentTask.formData, 'MeasureTime', this.$moment().format("YYYY-MM-DD HH:mm:ss"))
      } else {
        for (let k in this.currentTask.formData) {
          const editingLogData = this.editingLog[k.charAt(0).toLowerCase() + k.slice(1)]
          if (editingLogData) {
            this.currentTask.formData[k] = editingLogData
          }
        }
        this.$set(this.currentTask.formData, 'MeasureTime', this.$moment(this.editingLog.measuredDateTime).format("YYYY-MM-DD HH:mm:ss"))
      }
    },
    handleModalOk(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.nwFormComp.$refs.nwForm.$refs.validationObserver.validate().then(success => {
        if (success) {
          const data = JSON.parse(JSON.stringify(this.currentTask.formData))

          if (!this.editingLog) {
            data.MemberCode = this.filterValue.memberCode
            data.ReferenceCode = this.generateRandomString()
          } else {
            data.id = this.editingLog.id // For edit
            data.MemberCode = this.editingLog.memberCode
          }

          data.userID = this.userProfile.staffID
          data.MeasureTime = data.MeasureTime.split(" ").join("T")

          if (this.currentTask.taskType === 'bt') {
            data.Temperature = Number(data.Temperature)
          } else if (this.currentTask.taskType === 'bp') {
            data.Systolic = Number(data.Systolic)
            data.Diastolic = Number(data.Diastolic)
            data.Pulse = Number(data.Pulse)
          } else if (this.currentTask.taskType === 'bo') {
            data.SpO2 = Number(data.SpO2)
          } else if (this.currentTask.taskType === 'glucose') {
            data.Glucose = Number(data.Glucose)
          } else if (this.currentTask.taskType === 'breath') {
            data.Breaths = Number(data.Breaths)
          } else if (this.currentTask.taskType === 'weight') {
            data.Weight = Number(data.Weight)
          } else if (this.currentTask.taskType === 'height') {
            data.Height = Number(data.Height)
          }

          common.apiPostData(`/kiosk/${ !this.editingLog ? 'Add' : 'Update'}` + this.currentTask.requestTaskType, data)
            .then(() => {
              common.showToast({
                title: this.$t('kiosk.submit_success')
              })
              this.$refs.modalTask.hide()

              if (this.editingLog) {
                this.getList()
              }
            })
        }
      })
    },
    handleModalHidden() {
      this.resetModalFormData()
      this.currentTaskType=''

      if (this.editingLog) {
        this.editingLog = null;
      }
    },
    resetModalFormData() {
      this.currentTask.formData = JSON.parse(JSON.stringify(this.currentTask.defaultFormData))
      delete this.currentTask.defaultFormData
    },
  }
}