<template>
  <validation-observer ref="validationObserver">
    <b-form v-bind="$attrs" v-on="$listeners" ref="form">
      <b-form-group
        v-if="typeof form.MeasureTime !== 'undefined'"
        id="MeasureTime"
        :label="$t('kiosk.MeasureTime')"
        label-for="MeasureTime"
        label-cols="3"
      >
        <flat-pickr
          :value="form.MeasureTime"
          @input="handleMeasureTimeInput"
          class="form-control"
          :config="{
            dateFormat: 'Y-m-d H:i:S',
            time_24hr: true,
            enableTime: true,
            enableSeconds: true,
            minuteIncrement: 1,
          }"
        />
      </b-form-group>
      <slot></slot>
    </b-form>
  </validation-observer>
</template>

<script>
import {BFormGroup} from "bootstrap-vue"
import flatPickr from 'vue-flatpickr-component'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {required} from '@validations'

export default {
  name: "NwForm",
  model: {
    prop: 'nw-form-value',
    event: 'nw-form-input',
  },
  inheritAttrs: false,
  components: {BFormGroup, flatPickr, ValidationProvider, ValidationObserver},
  props: {
    nwFormValue: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      form: JSON.parse(JSON.stringify(this.nwFormValue)),
      required,
    }
  },
  watch: {
    nwFormValue: {
      handler: function (newValue) {
        this.form = JSON.parse(JSON.stringify(newValue))
      },
      deep: true,
    }
  },
  methods: {
    handleMeasureTimeInput(newValue) {
      if (newValue !== this.form.MeasureTime) {
        this.form.MeasureTime = newValue
        this.$emit("nw-form-input", JSON.parse(JSON.stringify(this.form)))
      }
    },
  },
}
</script>

<style scoped>

</style>