<template>
  <nw-form
    :nw-form-value="form"
    @nw-form-input="handleNwFormInput"
    ref="nwForm"
  >
    <b-form-group
      id="Glucose"
      :label="$t('kiosk.blood_glucose')"
      label-for="Glucose"
      label-cols="3"
    >
      <validation-provider
        :name="$t('kiosk.blood_glucose')"
        rules="required|between:1.1,33.3"
        #default="{ errors }"
      >
        <b-input-group
          append="mmol/L"
          class="input-group-merge"
        >
          <b-form-input
            id="Glucose"
            :value="form.Glucose"
            @input="v => { form.Glucose = v; emitFormData() }"
          />
        </b-input-group>
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>
    <b-form-group
      id="MeasuredPeriod"
      :label="$t('kiosk.MeasuredPeriod')"
      label-for="MeasuredPeriod"
      label-cols="3"
    >
      <validation-provider
        :name="$t('kiosk.MeasuredPeriod')"
        rules="required"
        #default="{ errors }"
      >
        <v-select
          :value="form.MeasuredPeriod"
          @input="v => { form.MeasuredPeriod = v; emitFormData() }"
          label="statusDesc"
          :options="selectOptions.bgPeriod"
          :reduce="option => option.statusCode"
          :searchable="false"
        />
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>
  </nw-form>
</template>

<script>
import nwFormTaskTypeMixin from "@/views/kiosk/components/kskNursingWork/mixins/nwFormTaskTypeMixin";
import vSelect from 'vue-select'

export default {
  name: "NwFormGlucose",
  mixins: [nwFormTaskTypeMixin],
  components: {vSelect},
}
</script>

<style scoped>

</style>