<template>
  <nw-form
    :nw-form-value="form"
    @nw-form-input="handleNwFormInput"
    ref="nwForm"
  >
    <b-form-group
      id="Emotional"
      :label="$t('kiosk.emotional')"
      label-for="Emotional"
      label-cols="3"
    >
      <validation-provider
        :name="$t('kiosk.emotional')"
        rules="required"
        #default="{ errors }"
      >
        <v-select
          :value="form.Emotional"
          @input="v => { form.Emotional = v; emitFormData() }"
          label="statusDesc"
          :options="selectOptions.emotional"
          :reduce="option => option.statusCode"
          :searchable="false"
        />
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>
  </nw-form>
</template>

<script>
import nwFormTaskTypeMixin from "@/views/kiosk/components/kskNursingWork/mixins/nwFormTaskTypeMixin";
import vSelect from 'vue-select'

export default {
  name: "NwFormEmotional",
  mixins: [nwFormTaskTypeMixin],
  components: {vSelect},
}
</script>

<style scoped>

</style>