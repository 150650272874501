<template>
  <nw-form
    :nw-form-value="form"
    @nw-form-input="handleNwFormInput"
    ref="nwForm"
  >
    <b-form-group
      id="Systolic"
      :label="$t('kiosk.systolic')"
      label-for="Systolic"
      label-cols="3"
    >
      <validation-provider
        :name="$t('kiosk.systolic')"
        rules="required|between:60,240"
        #default="{ errors }"
      >
        <b-input-group
          append="mmHg"
          class="input-group-merge"
        >
          <b-form-input
            id="Systolic"
            :value="form.Systolic"
            @input="v => { form.Systolic = v; emitFormData() }"
          />
        </b-input-group>
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>
    <b-form-group
      id="Diastolic"
      :label="$t('kiosk.diastolic')"
      label-for="Diastolic"
      label-cols="3"
    >
      <validation-provider
        :name="$t('kiosk.diastolic')"
        rules="required|between:40,140"
        #default="{ errors }"
      >
        <b-input-group
          append="mmHg"
          class="input-group-merge"
        >
          <b-form-input
            id="Diastolic"
            :value="form.Diastolic"
            @input="v => { form.Diastolic = v; emitFormData() }"
          />
        </b-input-group>
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>
    <b-form-group
      id="Pulse"
      :label="$t('kiosk.pulse')"
      label-for="Pulse"
      label-cols="3"
    >
      <validation-provider
        :name="$t('kiosk.pulse')"
        rules="required|between:40,200"
        #default="{ errors }"
      >
        <b-input-group
          append="bpm"
          class="input-group-merge"
        >
          <b-form-input
            id="Pulse"
            :value="form.Pulse"
            @input="v => { form.Pulse = v; emitFormData() }"
          />
        </b-input-group>
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>
  </nw-form>
</template>

<script>
import nwFormTaskTypeMixin from "@/views/kiosk/components/kskNursingWork/mixins/nwFormTaskTypeMixin";

export default {
  name: "NwFormBp",
  mixins: [nwFormTaskTypeMixin],
}
</script>

<style scoped>

</style>